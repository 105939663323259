import Vue from 'vue';
import VueRouter from 'vue-router';

import Auth from './AuthRoutes';
import Avaliacao from './AvaliacaoRoutes';
import Home from './HomeRoutes';
import Evento from './EventoRoutes';
import Email from './EmailRoutes';
import Inscricao from './InscricaoRoutes';
import Ticket from './TicketRoutes';
import Pessoa from './PessoaRoutes';
import Atendimento from './AtendimentoRoutes';
import AtendimentoPresencial from './AtendimentoPresencialRoutes';
import AtividadeProjeto from './AtividadeProjetoRoutes';
import Projeto from './ProjetoRoutes';
import Erro from './ErroRoutes';
import Proposta from './PropostaRoutes';
import Servico from './ServicoRoutes';
import Solicitacao from './SolicitacaoRoutes';
import PerfilRoutes from './PerfilRoutes';

import { Storage } from '../proxy-api/src/services/classes/Storage/Storage';

Vue.use(VueRouter);

const routes = [
  ...Home,
  ...Auth,
  ...Avaliacao,
  ...Evento,
  ...Email,
  ...Inscricao,
  ...Ticket,
  ...Pessoa,
  ...Erro,
  ...Atendimento,
  ...AtendimentoPresencial,
  ...AtividadeProjeto,
  ...Projeto,
  ...Proposta,
  ...Servico,
  ...Solicitacao,
  ...PerfilRoutes,
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'login' },
  },
];

const router = new VueRouter({ routes });
router.beforeEach((to, from, next) => {
  const autenticado = Storage.getCredenciais().token_Acesso;

  if (to.name === 'login' && autenticado) return next({ path: '/principal' });

  if (to.meta.requireAuth && !autenticado) {
    return next({ path: '/login', query: { redirect: to.fullPath } });
  }
  next();
});

export default router;
