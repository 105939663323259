import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Contato } from '../Contato/Contato';

export class Cliente extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
    this.contatos = [];
  }

  get json() {}

  set json(dados) {
    this.codigo = dados.codigoCli;
    this.descricao = dados.razaoCli;
    this.cnpj = dados.cnpjCli;
    this.inscricaoEstadual = dados.inscricaoCli;
    this.ativo = dados.ativoCli === 'S' ? true : false;
    this.email = dados.emailCli;

    if (dados?.clientesContatos) {
      dados.clientesContatos.map(registro => {
        const contato = new Contato();

        contato.json = registro;

        this.contatos.push(contato);
      });
    }
  }

  mapeamento = {
    codigo: 'codigoCli',
    descricao: 'razaoCli',
    cnpj: 'cnpjCli',
    inscricaoEstadual: 'inscricaoCli',
    ativo: 'ativoCli',
    email: 'emailCli',
    'contatos[].codigo': 'clientesContatos[].codigoCtc',
    'contatos[].descricao': 'clientesContatos[].descricaoCtc',
    'contatos[].email': 'clientesContatos[].emailCtc',
  };
}
